import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import DescriptionComponent from './DescriptionComponent'

const AnchorRodes = () => {
  const classes = styles();
  return (
    <div >
      <Container maxWidth="md" component="main" className={classes.main}>
        <Typography align="center" className={classes.pagetitle}>
          Anchor Rodes
        </Typography>
        <Grid container spacing={4} justify="center" aignItems="center">
        <Grid item xs={10} md={6} sm={6}>
          <div title="Anchor Rode and G4 Chain">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands.jpg?v=1646856734"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands.jpg?v=1646856734"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Anchor Rode and G4 Chain"
             
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={6}>
          <div title="Anchor Rode and G4 Chain Closeup">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands-close.jpg?v=1659477661"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands-close.jpg?v=1659477661"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Anchor Rode and G4 Chain Closeup"
              caption="Anchor Rode and G4 Chain Closeup"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Anchor Rodes"
          subtitle="Yachtkeeper anchor rodes are engineered with 100% nylon fibers in an
          8-plait construction for superior flexible endurance and less stretch,
          meaning your rode will last."
          p1=" Each rode also comes with a pre-installed
          length of Trident Marine Chains ISO Grade 40 Anchor Windlass Chain
          with short-pitch links engineered for maximum flexibility around the
          windlass and minimum wear."
          p2=" Sizes include 1/2” rope with 1/4” windlass chain and 5/8” rope with
          5/16” windlass chain, and lengths from 150 feet to 300 feet.
          Yachtkeeper anchor rodes are available in white nylon with red/blue
          tracers."
        />
      </Container>
      <Container maxWidth="lg" component="main" style={{ paddingBottom: "40px" }} >
        <div style={{ textAlign: "center", marginBottom: "5px", marginTop: "15px" }}>
          <h2 className={classes.tableHeading}>
           8-Plait Anchor Rodes
          </h2>
        </div>
      <TableContainer component={Paper} style={{marginBottom: '15px'}} >
      <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
        <TableHead style={{backgroundColor: '#1C2849'}}>
          <TableRow>
            <TableCell className={classes.theader}>Part Number</TableCell>
            <TableCell className={classes.theader}>Description</TableCell>
            <TableCell className={classes.theader}>Rope Type</TableCell>
            <TableCell className={classes.theader}>Rope Fiber</TableCell>            
            <TableCell className={classes.theader}>Rope Diameter</TableCell>            
            <TableCell className={classes.theader}>Rope Length (ft)</TableCell>
            <TableCell className={classes.theader}>Chain Type</TableCell>
            <TableCell className={classes.theader}>Chain Finish</TableCell>
            <TableCell className={classes.theader}>Chain Diameter</TableCell>
            <TableCell className={classes.theader}>Chain Length (ft)</TableCell>
            <TableCell className={classes.theader}>Anchor Rode Breaking Strength (lbs)</TableCell>
    
          </TableRow>
        </TableHead>
        <TableBody >         
            <TableRow>
            
            <TableCell className={classes.tcell}>91406704</TableCell>
            <TableCell className={classes.tcell}>	USA Rode 8P 1/2x150 1/4x10 G4</TableCell>
            <TableCell className={classes.tcell}>	8-Plait</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	150</TableCell>
            <TableCell className={classes.tcell}>	ISO Grade 40</TableCell>
            <TableCell className={classes.tcell}>	Hot Dip Galvanized</TableCell>
            <TableCell className={classes.tcell}>	0.25</TableCell>
            <TableCell className={classes.tcell}>	10</TableCell>
            <TableCell className={classes.tcell}>	6400</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91406718</TableCell>
            <TableCell className={classes.tcell}>	USA Rode 8P 1/2x200 1/4x15 G4</TableCell>
            <TableCell className={classes.tcell}>	8-Plait</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	200</TableCell>
            <TableCell className={classes.tcell}>	ISO Grade 40</TableCell>
            <TableCell className={classes.tcell}>	Hot Dip Galvanized</TableCell>
            <TableCell className={classes.tcell}>	0.25</TableCell>
            <TableCell className={classes.tcell}>	15</TableCell>
            <TableCell className={classes.tcell}>	6400</TableCell>
             </TableRow>    
             <TableRow>
             
             <TableCell className={classes.tcell}>91406744</TableCell>
             <TableCell className={classes.tcell}>	USA Rode 8P 1/2x300 1/4x20 G4</TableCell>
             <TableCell className={classes.tcell}>	8-Plait</TableCell>
             <TableCell className={classes.tcell}>	100% Nylon</TableCell>
             <TableCell className={classes.tcell}>	0.5</TableCell>
             <TableCell className={classes.tcell}>	300</TableCell>
             <TableCell className={classes.tcell}>	ISO Grade 40</TableCell>
             <TableCell className={classes.tcell}>	Hot Dip Galvanized</TableCell>
             <TableCell className={classes.tcell}>	0.25</TableCell>
             <TableCell className={classes.tcell}>	20</TableCell>
             <TableCell className={classes.tcell}>	6400</TableCell>
             </TableRow>    
             <TableRow>
             
             <TableCell className={classes.tcell}>91406752</TableCell>
             <TableCell className={classes.tcell}>	USA Rode 8P 5/8x150 5/16x10 G4</TableCell>
             <TableCell className={classes.tcell}>	8-Plait</TableCell>
             <TableCell className={classes.tcell}>	100% Nylon</TableCell>
             <TableCell className={classes.tcell}>	0.625</TableCell>
             <TableCell className={classes.tcell}>	150	</TableCell>
             <TableCell className={classes.tcell}>ISO Grade 40</TableCell>
             <TableCell className={classes.tcell}>	Hot Dip Galvanized</TableCell>
             <TableCell className={classes.tcell}>	0.3125</TableCell>
             <TableCell className={classes.tcell}>	10</TableCell>
             <TableCell className={classes.tcell}>	11000</TableCell>
             </TableRow>    
             <TableRow>
             
             <TableCell className={classes.tcell}>91406766</TableCell>
             <TableCell className={classes.tcell}>	USA Rode 8P 5/8x200 5/16x15 G4</TableCell>
             <TableCell className={classes.tcell}>	8-Plait</TableCell>
             <TableCell className={classes.tcell}>	100% Nylon</TableCell>
             <TableCell className={classes.tcell}>	0.625</TableCell>
             <TableCell className={classes.tcell}>	200</TableCell>
             <TableCell className={classes.tcell}>	ISO Grade 40</TableCell>
             <TableCell className={classes.tcell}>	Hot Dip Galvanized</TableCell>
             <TableCell className={classes.tcell}>	0.3125</TableCell>
             <TableCell className={classes.tcell}>	15</TableCell>
             <TableCell className={classes.tcell}>	11000</TableCell>
             </TableRow>    
             <TableRow>
             
             <TableCell className={classes.tcell}>91406792</TableCell>
             <TableCell className={classes.tcell}>	USA Rode 8P 5/8x300 5/16x20 G4</TableCell>
             <TableCell className={classes.tcell}>	8-Plait</TableCell>
             <TableCell className={classes.tcell}>	100% Nylon</TableCell>
             <TableCell className={classes.tcell}>	0.625</TableCell>
             <TableCell className={classes.tcell}>	300	</TableCell>
             <TableCell className={classes.tcell}>ISO Grade 40	</TableCell>
             <TableCell className={classes.tcell}>Hot Dip Galvanized</TableCell>
             <TableCell className={classes.tcell}>	0.3125</TableCell>
             <TableCell className={classes.tcell}>	20</TableCell>
             <TableCell className={classes.tcell}>	11000</TableCell>
             </TableRow>    
                 
          
                
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    </div>
  );
};

export default AnchorRodes;
