import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from "./DescriptionComponent";
import {Helmet} from 'react-helmet'

const UtilityRope = () => {
  const classes = styles();
  return (
    <div>


               <Helmet>
           <title>Power Pull Utility Ropes</title>
         </Helmet>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Typography
          align="center"
          className={classes.pagetitle}
          style={{ fontFamily: "Lato" }}
        >
          Power Pull Utility Rope
        </Typography>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={6}>
            <div title="United Strands Double Braid Pull Rope">
              <ModalImage
                small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-double-braid-pull-rope-united-strands.jpg?v=1646856748"
                large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-double-braid-pull-rope-united-strands.jpg?v=1646856748"
                hideDownload={true}
                hideZoom={true}
                className={classes.card}
                alt="United Strands Double Braid Pull Rope"
                caption="United Strands Double Braid Pull Rope"
              />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={6}>
          <div title="United Strands Double Braid Pull Rope Closeup">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-quarter-inch-double-braid-pull-rope-united-strands-close.jpg?v=1659477679"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-quarter-inch-double-braid-pull-rope-united-strands-close.jpg?v=1659477679"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              caption="United Strands Double Braid Pull Rope Closeup"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Power Pull Utility Rope" 
          subtitle="Power Pull is the premier utility line of American-made rope produced by
          United Strands of America." 
          p1="Power Pull utility ropes are double braided with a nylon core and
          polyester cover for low stretch and maximum abrasion resistance.
          Each utility rope also comes with a pre-spliced 6-inch eye at one end."
          p2="These wear-resistant workhorse ropes are engineered for long useful
          life without the strand slippage and breakage common in other ropes.
          Minimum Breaking Load limit on the 3/4” model is tested at 24,000 lbs,
          so you can put these ropes to work almost anywhere."
          p3="Sizes include 3/8”, 1/2”, 9/16”, 5/8”, and 3/4”, with lengths from 300
          feet to 1,000 feet. These utility pull ropes are available in white
          with blue flecks."
        />

      </Container>
      
      <Container maxWidth="lg" component="main">
        <div
          style={{
            textAlign: "center",
            marginBottom: "5px",
            marginTop: "15px",
          }}
        >
          <h2 className={classes.tableHeading}>
            Power Pull Utility Pull Ropes
          </h2>
        </div>
        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#1C2849" }}>
              <TableRow>
                <TableCell className={classes.theader}>Part Number</TableCell>
                <TableCell className={classes.theader}>Description</TableCell>
                <TableCell className={classes.theader}>Rope Type</TableCell>
                <TableCell className={classes.theader}>Rope Fiber</TableCell>
                <TableCell className={classes.theader}>Rope Diameter</TableCell>
                <TableCell className={classes.theader}>
                  Rope Length (ft)
                </TableCell>
                <TableCell className={classes.theader}>Rope End</TableCell>
                <TableCell className={classes.theader}>
                  Breaking Strength (lbs)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>91411282</TableCell>
                <TableCell className={classes.tcell}>
                  USA Pull Rope 3/8x600
                </TableCell>
                <TableCell className={classes.tcell}>Double Braid </TableCell>
                <TableCell className={classes.tcell}>
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}>0.375 </TableCell>
                <TableCell className={classes.tcell}>600 </TableCell>
                <TableCell className={classes.tcell}>6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}>5000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411284</TableCell>
                <TableCell className={classes.tcell}>
                  USA Pull Rope 7/16x300{" "}
                </TableCell>
                <TableCell className={classes.tcell}>Double Braid </TableCell>
                <TableCell className={classes.tcell}>
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}>0.4375 </TableCell>
                <TableCell className={classes.tcell}>300</TableCell>
                <TableCell className={classes.tcell}>6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}>7600</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411286</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 7/16x600
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.4375</TableCell>
                <TableCell className={classes.tcell}> 600</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 7600</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411288</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 1/2x300
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.5</TableCell>
                <TableCell className={classes.tcell}> 300 </TableCell>
                <TableCell className={classes.tcell}>6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 10000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411290</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 1/2x600
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.5</TableCell>
                <TableCell className={classes.tcell}> 600</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 10000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411291</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 1/2x1000
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.5 </TableCell>
                <TableCell className={classes.tcell}>1000</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 10000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411292</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 9/16x300
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.5625</TableCell>
                <TableCell className={classes.tcell}> 300</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 15000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411294</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 9/16x600
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.5625</TableCell>
                <TableCell className={classes.tcell}> 600</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 15000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411295</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 9/16x1000
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.5625</TableCell>
                <TableCell className={classes.tcell}> 1000</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 15000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411296</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 5/8x300
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.625 </TableCell>
                <TableCell className={classes.tcell}>300</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  6" Spliced Eye{" "}
                </TableCell>
                <TableCell className={classes.tcell}>18000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411298</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 5/8x600
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.625 </TableCell>
                <TableCell className={classes.tcell}>600 </TableCell>
                <TableCell className={classes.tcell}>6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 18000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411299</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 5/8x1000
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.625</TableCell>
                <TableCell className={classes.tcell}> 1000</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 18000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411300</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 3/4x300{" "}
                </TableCell>
                <TableCell className={classes.tcell}>Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.75</TableCell>
                <TableCell className={classes.tcell}> 300</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 24000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411302</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 3/4x600{" "}
                </TableCell>
                <TableCell className={classes.tcell}>Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.75</TableCell>
                <TableCell className={classes.tcell}> 600</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 24000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91411303</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  USA Pull Rope 3/4x1000
                </TableCell>
                <TableCell className={classes.tcell}> Double Braid</TableCell>
                <TableCell className={classes.tcell}>
                  {" "}
                  Nylon Core Polyester Cover
                </TableCell>
                <TableCell className={classes.tcell}> 0.75</TableCell>
                <TableCell className={classes.tcell}> 1000</TableCell>
                <TableCell className={classes.tcell}> 6" Spliced Eye</TableCell>
                <TableCell className={classes.tcell}> 24000</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Wheretobuy />
      </Container>
    </div>
  );
};

export default UtilityRope;
