import React from 'react'
import JotformEmbed from 'react-jotform-embed';
import {Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './../App.css';
import {Helmet} from 'react-helmet'



const useStyles = makeStyles(theme => ({
    pagetitle:{
     fontFamily:'Lato',
     paddingTop:'20px',
     fontSize: '50px'
    },
    button: {
      backgroundColor: '#1C2849'
    }
}));

function Contactform() {
    const classes = useStyles();
    
    return (
      <div>
        
<Helmet>
<title>Contact Form</title>
</Helmet>

      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center"className={classes.pagetitle} >
          CONTACT FORM
        </Typography>       
      </Container>
      <Grid container>
         <JotformEmbed src="https://form.jotform.com/212064999718166" style={{ color: "black" }} />
      </Grid>
      <div style={{marginBottom: '50px'}}></div>            
      </div>
    )
}
export default Contactform

