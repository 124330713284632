import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import DescriptionComponent from './DescriptionComponent'

const AnchorLines = () => {
  const classes = styles();
  return (
    <div>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Typography align="center" className={classes.pagetitle}>
          Anchor Lines
        </Typography>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={6}>
            <div title="Three strand nylon anchor line">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/five-eighths-inch-three-strand-nylon-anchor-line-united-strands.jpg?v=1646856735"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/five-eighths-inch-three-strand-nylon-anchor-line-united-strands.jpg?v=1646856735"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Three strand nylon anchor line"
              caption="Three strand nylon anchor line"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={6}>
            <div title="Three strand nylon anchor line">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-inch-three-strand-nylon-anchor-line-united-strands.jpg?v=1646856735"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-inch-three-strand-nylon-anchor-line-united-strands.jpg?v=1646856735"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
               alt="Three strand nylon anchor line"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Anchor Lines"
          subtitle="Yachtkeeper anchor lines are engineered with 100% nylon fibers and
          pre-installed 316 stainless steel thimble end fittings."
          p1="These anchor
          lines are available in either 3-strand or double-braid construction so
          you can choose the right rope for your equipment and needs."
          p2="Our anchor lines are compatible with several popular windlass
          manufacturers and are great for anchoring in a variety of water
          conditions."
          p3=" Both the triple-strand and double-braided styles come in 3/8”, 1/2”,
          and 5/8” diameters and lengths from 100 to 300 feet. These anchor
          lines are available in white with red/blue tracers."
        />
      </Container>
      <Container maxWidth="lg" component="main" style={{ paddingBottom: "40px" }} >
        <div style={{ textAlign: "center", marginBottom: "5px", marginTop: "15px" }}>
          <h2 className={classes.tableHeading}>
          3-Strand Anchor Lines
          </h2>
        </div>
      <TableContainer component={Paper} style={{marginBottom: '15px'}} >
      <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
        <TableHead style={{backgroundColor: '#1C2849'}}>
          <TableRow>
            <TableCell className={classes.theader}>Part Number</TableCell>
            <TableCell className={classes.theader}>Description</TableCell>
            <TableCell className={classes.theader}>Rope Type</TableCell>
            <TableCell className={classes.theader}>Rope Fiber</TableCell>            
            <TableCell className={classes.theader}>Rope Diameter</TableCell>            
            <TableCell className={classes.theader}>Rope Length (ft)</TableCell>
            <TableCell className={classes.theader}>End Fitting</TableCell>
            <TableCell className={classes.theader}>Boat Size</TableCell>
            <TableCell className={classes.theader}>Breaking Strength (lbs)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >         
            <TableRow>
            
            <TableCell className={classes.tcell}>91411000</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 3/8 x 100</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	100</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	Up to 25'</TableCell>
            <TableCell className={classes.tcell}>	3700</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411002</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 3/8 x 150</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	150</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	Up to 25'</TableCell>
            <TableCell className={classes.tcell}>	3700</TableCell>
            </TableRow>
            <TableRow>
            
            <TableCell className={classes.tcell}>91411004</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 3/8 x 200</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	200</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	Up to 25'</TableCell>
            <TableCell className={classes.tcell}>	3700</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411010</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 1/2 x 100</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	100</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	6400</TableCell>
            </TableRow>
            <TableRow>
            
            <TableCell className={classes.tcell}>91411012</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 1/2 x 150</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	150	</TableCell>
            <TableCell className={classes.tcell}>T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	6400</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411014</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 1/2 x 200</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	200</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	6400</TableCell>
            </TableRow>
            <TableRow>
            
            <TableCell className={classes.tcell}>91411016</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 1/2 x 300</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	300</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	6400</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411020</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 5/8 x 150</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625</TableCell>
            <TableCell className={classes.tcell}>	150</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	10400</TableCell>
            </TableRow>
            <TableRow>
            
            <TableCell className={classes.tcell}>91411022</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 5/8 x 200</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625</TableCell>
            <TableCell className={classes.tcell}>	200	</TableCell>
            <TableCell className={classes.tcell}>T316 Stainless Thimble	</TableCell>
            <TableCell className={classes.tcell}>35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	10400</TableCell>
            </TableRow>  
            <TableRow>
           
           <TableCell className={classes.tcell}> 91411024</TableCell>
            <TableCell className={classes.tcell}>	USA A3 Nyl 5/8 x 300</TableCell>
            <TableCell className={classes.tcell}>	3-Strand</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625</TableCell>
            <TableCell className={classes.tcell}>	300</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	10400</TableCell>
            </TableRow>
 
                 
          
                
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    <Container maxWidth="lg" component="main" style={{ paddingBottom: "40px" }} >
        <div style={{ textAlign: "center", marginBottom: "5px", marginTop: "15px" }}>
          <h2 className={classes.tableHeading}>
          Double Braid Anchor Lines
          </h2>
        </div>
      <TableContainer component={Paper} style={{marginBottom: '15px'}} >
      <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
        <TableHead style={{backgroundColor: '#1C2849'}}>
          <TableRow>
            <TableCell className={classes.theader}>Part Number</TableCell>
            <TableCell className={classes.theader}>Description</TableCell>
            <TableCell className={classes.theader}>Rope Type</TableCell>
            <TableCell className={classes.theader}>Rope Fiber</TableCell>            
            <TableCell className={classes.theader}>Rope Diameter</TableCell>            
            <TableCell className={classes.theader}>Rope Length (ft)</TableCell>
            <TableCell className={classes.theader}>End Fitting</TableCell>
            <TableCell className={classes.theader}>Boat Size</TableCell>
            <TableCell className={classes.theader}>Breaking Strength (lbs)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >         
            <TableRow>
            
            <TableCell className={classes.tcell}>91411040</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 3/8 x 100</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	100	</TableCell>
            <TableCell className={classes.tcell}>T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	Up to 25'</TableCell>
            <TableCell className={classes.tcell}>	4900</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411042</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 3/8 x 150</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	150	</TableCell>
            <TableCell className={classes.tcell}>T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	Up to 25'</TableCell>
            <TableCell className={classes.tcell}>	4900</TableCell>
            </TableRow>
            <TableRow>
            
            <TableCell className={classes.tcell}>91411044</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 3/8 x 200</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	200	</TableCell>
            <TableCell className={classes.tcell}>T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	Up to 25'	</TableCell>
            <TableCell className={classes.tcell}>4900</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411050</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 1/2 x 100</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5	</TableCell>
            <TableCell className={classes.tcell}>100</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	8500</TableCell>
            </TableRow>
            <TableRow>
           
           <TableCell className={classes.tcell}> 91411052</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 1/2 x 150</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	150</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	8500</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411054</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 1/2 x 200</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	200	</TableCell>
            <TableCell className={classes.tcell}>T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	8500</TableCell>
            </TableRow>
            <TableRow>
            
            <TableCell className={classes.tcell}>91411056</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 1/2 x 300</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5	</TableCell>
            <TableCell className={classes.tcell}>300</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	8500</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411060</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 5/8 x 150</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625</TableCell>
            <TableCell className={classes.tcell}>	150</TableCell>
            <TableCell className={classes.tcell}>	T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	15200</TableCell>
            </TableRow>
            <TableRow>
            
            <TableCell className={classes.tcell}>91411062</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 5/8 x 200</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625</TableCell>
            <TableCell className={classes.tcell}>	200	</TableCell>
            <TableCell className={classes.tcell}>T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	15200</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411064</TableCell>
            <TableCell className={classes.tcell}>	USA ADB Nyl 5/8 x 300</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625	</TableCell>
            <TableCell className={classes.tcell}>300	</TableCell>
            <TableCell className={classes.tcell}>T316 Stainless Thimble</TableCell>
            <TableCell className={classes.tcell}>	35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	15200</TableCell>
            </TableRow>
 
                 
          
                
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    </div>
  );
};

export default AnchorLines;
