
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4'

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
      if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-7GBMDQ9MY4");
      }
      setInitialized(true);
  }, []);

  useEffect(() => {
      if (initialized) {
        console.log(location.pathname + location.search)
      ReactGA.send({hitType: "pageview", page: `${location.pathname}${location.search}`});
      }
  }, [initialized, location]);
};

export default useGaTracker;
