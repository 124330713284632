import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Topnav from "../components/Topnav";
import Scroll from "./Scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tree: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  list: {
    width: 200,
    fontFamily: "Lato",
  },
  sideBarIcon: {
    color: "white",
    cursor: "pointer",
  },
  link: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: "bold",
    textDecoration: "none",
    color: "#ebebe6",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
  },
  reslink: {
    textDecoration: "none",
    color: "#213C54",
  },
  reslink2: {
    textDecoration: "none",
    color: "#213C54",
    fontSize: "15px",
  },
}));

function ElevateAppBar() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Topnav />
      <AppBar
        className={classes.root}
        position="sticky"
        style={{ backgroundColor: "#1c2849", }}
      >
        <Toolbar>
          <Typography align="center">
            <Scroll />
          </Typography>

          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
     
          </Typography>

          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
            <Link to="/yachtkeeper" className={classes.link}>
              Yachtkeeper Marine Ropes
            </Link>
          </Typography>

          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
            <Link to="/power-pull" className={classes.link}>
              Power Pull Utility Ropes
            </Link>
          </Typography>
          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >

          </Typography>

        </Toolbar>
        
      </AppBar>
    </React.Fragment>
  );
}

export default ElevateAppBar;
