import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Carousel1 from "../assets/Carousel1.png";
import Carousel3 from "../assets/Carousel3.png";
import Carousel4 from "../assets/Carousel4.jpg";
import Carousel5 from "../assets/Carousel5.jpg";
import Carousel6 from "../assets/Carousel6.png";
import Backtotop from "./Backtotop";
import About from "./About";
import Contact from "./Contact";
import Wheretobuy from "./Wheretobuy";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Helmet } from 'react-helmet'

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  // top: 'calc(50% - 15px)',
  top: "5%",
  width: "3%",
  height: "100%",
  color: "rgb(28,40,73)",
  cursor: "pointer",
  "&hover": {
    top: 0,
    bottom: 0,
    backgroundColor: "lightgrey",
    opacity: ".4",
  },
};

function Home() {
  return (
    <div>
               <Helmet>
           <title>United Strands of America</title>
         </Helmet>
      <Backtotop />
      <Carousel
        autoPlay
        transitionTime={1000}
        interval={8000}
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <ArrowBackIosIcon
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, left: 15 }}
            />
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <ArrowForwardIosIcon
              onClick={onClickHandler}
              title={label}
              style={{ ...arrowStyles, right: 15 }}
            />
          )
        }
      >
        <div>
          <img src={Carousel4} alt="United Strands High Performance Rope" />
        </div>
        <div>
          <img src={Carousel6} alt="United Strands Rope Properly Made In The USA" />
        </div>
        <div>
          <img src={Carousel5} alt="United Strands High Quality 100% Nylon Rope" />
        </div>
        <div>
          <img src={Carousel3} alt="Power Pull Rope" />
        </div> 
        <div>
          <img src={Carousel1} alt="United Strands Quality Certified" />
        </div>
      </Carousel>
      <Wheretobuy />
      <Contact />
      <About />
    </div>
  );
}
export default Home;
