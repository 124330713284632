import React from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import sld from "./../assets/sld-logo.svg"
import erigging from './../assets/erigging.png'

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    paddingBottom: "35px",
    fontSize: "50px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      padding: "20px",
    },
  },
  pagesubtitle: {
    paddingBottom: "40px",
  },
  img: {
    height: "66px",
   
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
    [theme.breakpoints.down("sm")]: {
      height: "45px"
    },
  },
}));

export default function Wheretobuy() {
  const classes = useStyles();
  return (
    <Container
    maxWidth="md"
    style={{
      marginBottom:'15px',
      padding:'15px',
      display: "flex",
      alignItems: "center", justifyContent:'center', border:'10px solid #e4e4e4'
    }}
  >
    <div style={{ flexBasis: "none", marginBottom: '30px' }}>   
            <p  style={{fontSize:'30px'}} align="center"  >
            Where to Buy
            </p>   
        <Grid container direction="row" spacing={6} justify="center" alignItems="center" >           
            <Grid item md={12} xs={12} style={{flexBasis:'0'}} >
            <a href="https://www.e-rigging.com/yachtkeeper-products" target="_blank" rel="noopener noreferrer">
                <img src ={erigging} className={classes.img} alt=""/></a>
            </Grid>
            {/* <Grid item md={4} xs={12} style={{flexBasis:'0'}}>
            <a href="https://www.amazon.com/s?k=Thor-Tex" target="_blank" rel="noopener noreferrer">
                <img src ={wtb1} className={classes.img} alt=""/></a>
            </Grid>    */}
            {/* <Grid item md={6} xs={12} style={{flexBasis:'0'}}>
            <a href="https://sldrigging.com/contactform" target="_blank" rel="noopener noreferrer">
                <img src ={sld} className={classes.img} alt=""/></a>
            </Grid> */}
        </Grid>    
        </div>   
        </Container>
    
  );
}














