import './App.css';
import React from "react";
import { Switch,  Route} from "react-router-dom";
import Privacy from './components/Privacy'
import Termsofuse from './components/Termsofuse'
import Contactform from './components/Contactform'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from './components/Appbar'
import  Box   from '@material-ui/core/Box';
import Drawer from './components/Drawer'
import Footer from './components/Footer';
import Home from './components/Home';
import UtilityRope from './components/UtilityRope'
import Yachtkeeper from './components/Yachtkeeper'
import useGaTracker from './useGaTracker'


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

 function App() {

  useGaTracker();
  
  return (
    <div>
       <ScrollToTop /> 
       <Navbar/>   
      <Box display={{ md: 'block', lg: 'none' }} ><Drawer/></Box> 

       <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/contact-form" component={Contactform}></Route>
        <Route path="/privacy-policy" component={Privacy}></Route>
        <Route path="/terms-of-use" component={Termsofuse}></Route>
        <Route path="/power-pull" component={UtilityRope}></Route>
        <Route path="/yachtkeeper" component={Yachtkeeper}></Route>
      </Switch>
      <Footer/>
    </div>
  );
}


export default App;
