import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from './styles/AboutStyles'
import aboutImg from './../assets/UNITED_STRANDS_ABOUT_US_GRAPHIC.jpg'

export default function About() {
  const classes = styles();
  return (
    <div
      id="about"
      className={classes.aboutDiv}
    >
      <Container
        maxWidth="lg"
        style={{
          flexGrow: "1",
        }}
      >
        <Typography align="center" className={classes.pagetitle}>
          About Us
        </Typography>

        <div style={{ flexBasis: "none" }}>
          <Grid container>
            <Grid
              item
              lg={5}
              xs={12}
              md={4}
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <img src={aboutImg} className={classes.aboutimg} alt="aboutimg" title="aboutimg" /> 
            </Grid>

            <Grid item lg={7} xs={12} md={8} style={{ padding: "0%" }}>
              <Typography className={classes.pagecontent} component="p">
              We believe that our team is our strength here at United Strands of America.
              We weave together our design experience with high-quality materials and strict quality standards
              to bring you the best American-made rope products.
              </Typography>
              <Typography className={classes.pagecontent} component="p">
              Our decades of experience in the marine industry guides us to develop ropes that meet the demands of our customers. 
              We create our products to fill their roles, and not to be one-size-fits-all. 
              You get a rope and fittings you can depend on season after season.
              </Typography>
              <Typography className={classes.pagecontent} component="p">
              Our anchor rodes, anchor lines, dock lines, fender lines, and utility pull ropes are engineered for sustained 
              performance in the toughest conditions, with all the safety and reliability you expect from critical rigging equipment.
              </Typography>
              <Typography className={classes.pagecontent} component="p">
              United Strands of America ropes, lines, and rodes are products of Sea‐Land Distributors, LLC, and are made with pride in the USA.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
