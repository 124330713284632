import React from 'react';
import Typography from '@material-ui/core/Typography';

import logo from '../assets/HorizontalLogo.png'
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box } from '@material-ui/core';

const useStyles = makeStyles({
logo: {
    width: '75%',
    margin: '10px',
    marginLeft: '30px',
},  
toplink: { 
  fontFamily: 'Lato',
  fontSize: '20px',
  textDecoration: 'none',
  color: 'black',  
  margin: 'auto',
  padding:'10px',
'&:hover': {
  background: "lightgrey",
},
},
});

function Test2 () { 
  const classes = useStyles();

  return (
    <React.Fragment>   
         <Box display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>  
         <Grid  container  direction="row"  justify="space-around"  alignItems="center" style={{backgroundColor: '#e4e4e4'}}>
      <Grid container item lg={5} md={5}>
      <Link to="/">
             <img src={logo} className={classes.logo} alt="logo"/></Link>
      </Grid>
      <Grid container item lg={7} md={7} justify="flex-end" style={{paddingRight: '75px'}}>  
      <Typography >
           <a target="_blank" rel="noopener noreferrer" href="https://e-rigging.com/collections/shop?q=united%20strands" className={classes.toplink}>Products</a></Typography>
          <Typography >
           <a href="/#where-to-buy" className={classes.toplink}>Where to Buy</a></Typography>
           <Typography> 
          <a href="/#about" className={classes.toplink}>About</a></Typography>
          <Typography > 
          <a href="/#contact" className={classes.toplink}>Contact</a></Typography>
     </Grid>
     </Grid>       
      </Box>  
      {/* mobile  */}
      <Box	display={{ xs: 'block', sm: 'none' }} > 
      <div align="center" style={{marginTop: '55px'}}>               
      </div>        
      </Box>      
    </React.Fragment>
  );
};

export default Test2;