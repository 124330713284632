import React from 'react'
import  AnchorRodes from './AnchorRodes'
import AnchorLines from './AnchorLines'
import DockLines from './DockLines'
import WhereToBuy3 from './WhereToBuy3'
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CardActionArea from "@material-ui/core/CardActionArea";
import DescriptionComponent from './DescriptionComponent'
import {Helmet} from 'react-helmet'
function Yachtkeeper() {
  const classes = styles();
  return (
    <div>
               <Helmet>
           <title>Yachtkeeper Marine Ropes</title>
         </Helmet>
       <Container maxWidth="md" component="main" className={classes.main} >
        <Typography align="center" className={classes.pagetitle}>
          Yachtkeeper Marine Ropes
        </Typography>


        <Grid container justify="center" spacing={4} style={{marginBottom: '20px', marginTop: '20px'}} >
       

        <Grid item>
          <Card style={{ maxWidth: 240 }}>
            <CardActionArea>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center"
                }}
              >
                <CardMedia
                  style={{
                    width: "auto",
                    maxHeight: "185px"
                  }}
                  component="img"
                  image="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/half-inch-8-plait-nylon-anchor-rode-and-five-sixteenths-inch-G4-chain-united-strands.jpg?v=1646856734"
                  title="Anchor Rode"
                  alt="Anchor Rode"
                />
              </div>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                Anchor Rodes
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                100% Nylon fibers in an 8-plait construction
          </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.shopButtonDiv}>
              <Button size="small" color="primary" className={classes.shopButton}>
                <Link className={classes.shopButtonLink} href="https://www.e-rigging.com/Rope_c_4060.html" >Shop Now</Link>
              </Button>
            </CardActions>
          </Card>
          
        </Grid>


        <Grid item>
          <Card style={{ maxWidth: 240 }}>
            <CardActionArea>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center"
                }}
              >
                <CardMedia
                  style={{
                    width: "auto",
                    maxHeight: "185px"
                  }}
                  component="img"
                  image="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/three-eighths-inch-three-strand-nylon-anchor-line-united-strands.jpg"
                  title="Anchor Line"
                  alt="Anchor Line"
                />
              </div>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Anchor Lines
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                100% Nylon fibers & stainless steel thimble end fittings
          </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.shopButtonDiv}>
              <Button size="small" color="primary" className={classes.shopButton}>
                <Link className={classes.shopButtonLink} href="https://www.e-rigging.com/Rope_c_4060.html" >Shop Now</Link>
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item>
          <Card style={{ maxWidth: 240 }}>
            <CardActionArea>
              <div
                style={{
                  display: "flex",
                  alignItem: "center",
                  justifyContent: "center"
                }}
              >
                <CardMedia
                  style={{
                    width: "auto",
                    maxHeight: "185px"
                  }}
                  component="img"
                  image="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/three-eighths-dock-line.jpg?v=1646941194"
                  title="Dock Line"
                />
              </div>
              <CardContent>
                <Typography gutterBottom variant="headline" component="h2">
                  Dock Lines
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                Double Braided 100% Nylon Dock Line
          </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions className={classes.shopButtonDiv}>
              <Button size="small" color="primary" className={classes.shopButton}>
                <Link className={classes.shopButtonLink} href="https://www.e-rigging.com/yachtkeeper-dock-lines-marine" >Shop Now</Link>
              </Button>
            </CardActions>
          </Card>
        </Grid>
       
      </Grid>
        <DescriptionComponent 
          product="Yachtkeeper Marine Rope"
          subtitle="Yachtkeeper is the premier marine line of American-made rope produced by
          United Strands of America."
          p1="Yachtkeeper anchor rodes, anchor lines, fender lines, and dock lines are manufactured with high-quality 100% nylon for reliable performance.
          Our ropes are also purpose-engineered to fulfill their intended roles consistently and efficiently."
        />
      </Container>
      
      <br />
      <AnchorRodes />
      <AnchorLines id="AnchorLines"/>
      <DockLines />
      <WhereToBuy3 />
    </div>
  )
}

export default Yachtkeeper