import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import sld from "./../assets/sld-logo.svg"
import erigging from './../assets/erigging.png'

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    paddingBottom: "35px",
    fontSize: "50px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      padding: "20px",
    },
  },
  pagesubtitle: {
    paddingBottom: "40px",
  },
  img: {
    height: "50%",
    width: "50%",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
  },
}));

export default function Wheretobuy() {
  const classes = useStyles();
  return (
    <div
      id="where-to-buy"
      style={{ backgroundColor: "#d6d9dd", display: "flex", padding: "70px 0"  }}
    >
      <Container
        maxWidth="lg"
        style={{
          flexGrow: "1",
          marginBottom: "20px"
        }}
      >
        <Typography align="center" className={classes.pagetitle} style={{fontFamily: 'Lato', fontWeight: 400, color: '#231f20'}}>
          Where to Buy
        </Typography>
        <Grid container spacing={3} justify="center" alignItems="center" style={{textAlign:'center'}}>
          <Grid item xs={12} md={12} lg={12}>
            <a
              href="https://e-rigging.com/collections/shop?q=united%20strands"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={erigging} className={classes.img} alt="" />
            </a>
          </Grid>
          {/* <Grid item  xs={12} lg={4}>
            <a
              href="https://www.amazon.com/s?k=Thor-Tex"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={wtb1} className={classes.img} alt="" />
            </a>
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={6}>
            <a
              href="https://sldrigging.com/contactform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={sld} className={classes.img} style={{width: "40%", height: "40%"}} alt="" />
              
            </a>
          
          </Grid> */}
        </Grid>
      </Container>
    </div>
  );
}
