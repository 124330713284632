import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  pagetitle: {
    color: "#231f20",
    fontFamily: "Lato",
    paddingTop: "30px",
    fontSize: "42px",
  },
  pagesubtitle: {
    paddingBottom: "16px",
    fontFamily: "Lato",
    fontSize: "20px",
  },
  pagecontent: {
    fontFamily: 'Lato',
    margin: '10px',
    fontSize: '18px'
},
  aboutimg: {
    width: "80%",
    padding: "10px",
    textAlign: "center",
    justifyContent: "center",
  },
  aboutDiv: { 
    backgroundColor: "#d6d9dd", 
    display: "flex", 
    padding: "4% 0" 
  },
}));
