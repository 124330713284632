import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import DescriptionComponent from './DescriptionComponent'

const DockLines = () => {
  const classes = styles();
  return (
    <div>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Typography align="center" className={classes.pagetitle}>
          Dock Lines
        </Typography>

        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={6}>
            <div title="Double braid nylon dock line">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-twenty-five-ft-double-braid-nylon-dock-line-white-USA.jpg?v=1659476911"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-twenty-five-ft-double-braid-nylon-dock-line-white-USA.jpg?v=1659476911"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Double braid nylon dock line"
              caption="double braid nylon dock line"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={6}>
            <div title="Double braid nylon dock line">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-twenty-ft-double-braid-nylon-dock-line-white-USA-opt2.jpg?v=1659477664"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-twenty-ft-double-braid-nylon-dock-line-white-USA-opt2.jpg?v=1659477664"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Double braid nylon dock line"
              caption="double braid nylon dock line"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Dock Lines"
          subtitle="Yachtkeeper dock lines are engineered with 100% double-braid nylon
          fibers for maximum strength, flexing endurance, and resistance to rot."
          p1="Double-braid nylon stretches less than three-strand lines and is a bit
          stronger size-for-size. Our dock lines also feature a pre-spliced eye
          so you can put it to work right away. These ropes are great for
          mooring, docking, and berthing."
          p2="Sizes include 3/8” up to 3/4”, and lengths from 15 feet to 50 feet.
          These mooring lines are available in white with red/blue tracers,
          white and gold, all-black, and all-blue."
        />
      </Container>
      <Container maxWidth="lg" component="main" style={{ paddingBottom: "40px" }} >
        <div style={{ textAlign: "center", marginBottom: "5px", marginTop: "15px" }}>
          <h2 className={classes.tableHeading}>
          White Dock Lines (also available in black, gold/white, and blue)
          </h2>
        </div>
      <TableContainer component={Paper} style={{marginBottom: '15px'}} >
      <Table className={classes.table} aria-label="simple table" style={{padding: '5px'}}>
        <TableHead style={{backgroundColor: '#1C2849'}}>
          <TableRow>
            <TableCell className={classes.theader}>Part Number</TableCell>
            <TableCell className={classes.theader}>Description</TableCell>
            <TableCell className={classes.theader}>Rope Type</TableCell>
            <TableCell className={classes.theader}>Rope Fiber</TableCell>            
            <TableCell className={classes.theader}>Rope Diameter</TableCell>            
            <TableCell className={classes.theader}>Rope Length (ft)</TableCell>
            <TableCell className={classes.theader}>Rope End</TableCell>
            <TableCell className={classes.theader}>Boat Length</TableCell>
            <TableCell className={classes.theader}>Breaking Strength (lbs)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >         
            <TableRow>
            
            <TableCell className={classes.tcell}>91411080</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 3/8 x 15	</TableCell>
            <TableCell className={classes.tcell}>Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	15</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye	</TableCell>
            <TableCell className={classes.tcell}>Up to 25'</TableCell>
            <TableCell className={classes.tcell}>	4900</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411082</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 3/8 x 20</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	20</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	Up to 25'</TableCell>
            <TableCell className={classes.tcell}>	4900</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411084</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 3/8 x 25</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.375</TableCell>
            <TableCell className={classes.tcell}>	25</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	Up to 25'</TableCell>
            <TableCell className={classes.tcell}>	4900</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411120</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 1/2 x 15</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	15	</TableCell>
            <TableCell className={classes.tcell}>12" Spliced Eye	</TableCell>
            <TableCell className={classes.tcell}>25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	8500</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411122</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 1/2 x 20</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5</TableCell>
            <TableCell className={classes.tcell}>	20</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye	</TableCell>
            <TableCell className={classes.tcell}>25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	8500</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411124</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 1/2 x 25</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.5	</TableCell>
            <TableCell className={classes.tcell}>25</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	25' to 35'</TableCell>
            <TableCell className={classes.tcell}>	8500</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411160</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 5/8 x 20</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625</TableCell>
            <TableCell className={classes.tcell}>	20</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye</TableCell>
     
            <TableCell className={classes.tcell}>	35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	15200</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411161</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 5/8 x 25</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625	</TableCell>
            <TableCell className={classes.tcell}>25</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	15200</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411162</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 5/8 x 30</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625</TableCell>
            <TableCell className={classes.tcell}>	30</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye </TableCell>
            <TableCell className={classes.tcell}>	35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	15200</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411163</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 5/8 x 35</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625	</TableCell>
            <TableCell className={classes.tcell}>35</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye	</TableCell>
            <TableCell className={classes.tcell}>35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	15200</TableCell>
            </TableRow>  
            <TableRow>
            <TableCell className={classes.tcell}>91411164</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 5/8 x 40</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.625</TableCell>
            <TableCell className={classes.tcell}>	40</TableCell>
            <TableCell className={classes.tcell}>	12" Spliced Eye	</TableCell>
            <TableCell className={classes.tcell}>35' to 45'</TableCell>
            <TableCell className={classes.tcell}>	15200</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411200</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 3/4 x 25</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.75</TableCell>
            <TableCell className={classes.tcell}>	25</TableCell>
            <TableCell className={classes.tcell}>	18" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	40' to 60'</TableCell>
            <TableCell className={classes.tcell}>	18000</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411201</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 3/4 x 30</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.75</TableCell>
            <TableCell className={classes.tcell}>	30</TableCell>
            <TableCell className={classes.tcell}>	18" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	40' to 60'</TableCell>
            <TableCell className={classes.tcell}>	18000</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411202</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 3/4 x 35</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.75</TableCell>
            <TableCell className={classes.tcell}>	35</TableCell>
            <TableCell className={classes.tcell}>	18" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	40' to 60'</TableCell>
            <TableCell className={classes.tcell}>	18000</TableCell>
            </TableRow>  
            <TableRow>
           
           <TableCell className={classes.tcell}> 91411203</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 3/4 x 40</TableCell>
            <TableCell className={classes.tcell}>	Double Braid</TableCell>
            <TableCell className={classes.tcell}>	100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.75</TableCell>
            <TableCell className={classes.tcell}>	40</TableCell>
            <TableCell className={classes.tcell}>	18" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	40' to 60'</TableCell>
            <TableCell className={classes.tcell}>	18000</TableCell>
            </TableRow>  
            <TableRow>
            
            <TableCell className={classes.tcell}>91411204</TableCell>
            <TableCell className={classes.tcell}>	USA DBB Nyl Dck Wht 3/4 x 50</TableCell>
            <TableCell className={classes.tcell}>	Double Braid	</TableCell>
            <TableCell className={classes.tcell}>100% Nylon</TableCell>
            <TableCell className={classes.tcell}>	0.75</TableCell>
            <TableCell className={classes.tcell}>	50</TableCell>
            <TableCell className={classes.tcell}>	18" Spliced Eye</TableCell>
            <TableCell className={classes.tcell}>	40' to 60'</TableCell>
            <TableCell className={classes.tcell}>	18000</TableCell>
            </TableRow>  
            <TableRow>
            
            </TableRow>  
            <TableRow>
            
            </TableRow>  
            
            

 
                 
          
                
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
    </div>
  );
};

export default DockLines;
